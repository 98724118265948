import { Injectable } from '@angular/core';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';

@Injectable()
export class MissingGeoPositionService {
    missingGeoPosition: SelectOptions[] = [
        {
            label: 'Tak',
            value: 'true',
        },
        {
            label: 'Nie',
            value: 'false',
        },
    ];
}
