import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SelectOptions } from '@app/shared/models/multiselec-options.model';

@Injectable()
export class PaymentStatusService {
    getPaymentStatus(): Observable<SelectOptions[]> {
        const paymentStatus: SelectOptions[] = [
            {
                label: 'DICTIONARIES.PAYMENT_STATUS.yes',
                value: 'completed',
            },
            {
                label: 'DICTIONARIES.PAYMENT_STATUS.no',
                value: 'pending',
            },
        ];

        return of(paymentStatus);
    }
}
