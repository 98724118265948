import { Injectable } from '@angular/core';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';

@Injectable()
export class RideStatusService {
    rideStatus: SelectOptions[] = [
        {
            label: 'Tak',
            value: 'resigned',
        },
        {
            label: 'Nie',
            value: 'completed',
        },
    ];
}
