import { Injectable } from '@angular/core';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { TrueFalseNumericEnum } from '@app/shared/table/enums/true-false-numeric.enum';

@Injectable({
    providedIn: 'root',
})
export class TrueFalseNumericService {
    trueFalseNumeric: SelectOptions[] = [
        {
            label: TrueFalseNumericEnum.YES,
            value: '1',
        },
        {
            label: TrueFalseNumericEnum.NO,
            value: '0',
        },
    ];
}
