import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { PLANNING_TYPE } from '@app/store/planning/enums';

@Injectable()
export class PlanningTypesService {
    getPlanningTypes(): Observable<SelectOptions[]> {
        const PlanningTypes: SelectOptions[] = [
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_morning',
                value: PLANNING_TYPE.MORNING,
            },
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_afternoon',
                value: PLANNING_TYPE.AFTERNOON,
            },
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_dedicated',
                value: PLANNING_TYPE.DEDICATED,
            },
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_morning_return',
                value: PLANNING_TYPE.MORNING_RETURN,
            },
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_afternoon_return',
                value: PLANNING_TYPE.AFTERNOON_RETURN,
            },
            {
                label: 'DICTIONARIES.SELECT_PLANNING_TYPES.planning_type_dedicated_return',
                value: PLANNING_TYPE.DEDICATED_RETURN,
            },
        ];

        return of(PlanningTypes);
    }
}
