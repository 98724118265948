import { Injectable } from '@angular/core';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { DidntGoTypes } from '@app/shared/table/enums/didnt-go.enum';

@Injectable()
export class DidntGoService {
    didntGo: SelectOptions[] = [
        {
            label: DidntGoTypes.YES,
            value: '1',
        },
        {
            label: DidntGoTypes.NO,
            value: '0',
        },
    ];
}
