import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Roles } from '@app/modules/permissions';
import { SelectOptions } from '@shared/models/multiselec-options.model';

@Injectable()
export class UserRolesService {
    getUserRoles(): Observable<SelectOptions[]> {
        const userRoles: SelectOptions[] = [
            {
                label: 'USERS.ROLES.admin',
                value: Roles.ROLE_ADMIN,
            },
            {
                label: 'USERS.ROLES.cc',
                value: Roles.ROLE_CALL_CENTER,
            },
            {
                label: 'USERS.ROLES.partner',
                value: Roles.ROLE_PARTNER_EMPLOYEE,
            },
            {
                label: 'USERS.ROLES.driver_accountant',
                value: Roles.ROLE_DRIVER_ACCOUNTANT,
            },
            {
                label: 'USERS.ROLES.partner_accountant',
                value: Roles.ROLE_PARTNER_ACCOUNTANT,
            },
            {
                label: 'USERS.ROLES.fleet_manager',
                value: Roles.ROLE_FLEET_MANAGER,
            },
            {
                label: 'USERS.ROLES.dispatcher',
                value: Roles.ROLE_DISPATCHER,
            },
            {
                label: 'USERS.ROLES.driver',
                value: Roles.ROLE_DRIVER,
            },
            {
                label: 'USERS.ROLES.user',
                value: Roles.ROLE_USER,
            },
        ];

        return of(userRoles);
    }
}
