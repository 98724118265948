import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { PaymentTypes } from '@app/shared/enums/payment-types.enum';

@Injectable()
export class PaymentTypesService {
    getPaymentTypes(): Observable<SelectOptions[]> {
        const currencyTypes: SelectOptions[] = [
            {
                label: '---',
                value: PaymentTypes.PAYMENT_UNDEFINED,
            },
            {
                label: 'DICTIONARIES.SELECT_PAYMENT_TYPES.payment_processor',
                value: PaymentTypes.PAYMENT_PROCESSOR,
            },
            {
                label: 'DICTIONARIES.SELECT_PAYMENT_TYPES.cash',
                value: PaymentTypes.CASH,
            },
            {
                label: 'DICTIONARIES.SELECT_PAYMENT_TYPES.transfer',
                value: PaymentTypes.TRANSFER,
            },
            {
                label: 'DICTIONARIES.SELECT_PAYMENT_TYPES.company_invoice',
                value: PaymentTypes.COMPANY_INVOICE,
            },
        ];

        return of(currencyTypes);
    }
}
