import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { SelectOptions } from '@app/shared/models/multiselec-options.model';

@Injectable()
export class ResignedStatusService {
    getResignedStatuses(): Observable<SelectOptions[]> {
        const ResignedStatuses: SelectOptions[] = [
            {
                label: 'DICTIONARIES.RESIGNED_STATUS_TYPES.resigned',
                value: 'resigned',
            },
            {
                label: 'DICTIONARIES.RESIGNED_STATUS_TYPES.not_resigned',
                value: 'not_resigned',
            },
        ];

        return of(ResignedStatuses);
    }
}
